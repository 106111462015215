body {
  margin: 0;
  padding: 0;

  word-break: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: rgb(24, 21, 21);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#a1 {
  color: white;
}

.white {
  fill: white;
}
.black {
  fill: black;
}
.blue {
  fill: rgb(33, 151, 255);
}

.yellow {
  fill: yellow;
}

.orange {
  fill: orange;
}

.heder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  height: 100vh;
  align-items: center;
}

.header {
  display: flex;
  animation: moveUx 8.5s;
  animation-fill-mode: forwards;

  opacity: 0;
  flex-direction: row;
}

#cont1 {
  display: flex;
  text-align: center;
  justify-content: center;
}

#container {
  padding-top: 2vh;
  border-top-left-radius: 2vw;
  border-top-right-radius: 2vw;
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
  opacity: 0;
  align-items: center;

  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  animation: moveUx 2s;
  animation-fill-mode: forwards;

  width: 80vw;
  background-color: white;
  -moz-box-shadow: inset 0 0 10px #e0e0e0;
  -webkit-box-shadow: inset 0 0 10px #e0e0e0;
  box-shadow: inset 0 0 10px #e0e0e0;
  -webkit-box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 14px -7px rgba(0, 0, 0, 0.75);
}

#sup {
  display: none;
}

@media screen and (max-width: 309px) {
  .main {
    display: none;
  }
  #container12 {
    display: none;
  }
  #container1,
  .content0,
  #p7 {
    display: none;
  }

  .hamburger,
  .img1,
  #intro,
  .content3,
  .content4,
  .container1,
  .container2,
  .contain,
  .heder {
    display: none;
    visibility: hidden;
  }

  #navbur {
    display: none !important;
  }
  #texts3 {
    padding-bottom: 12vh;
  }

  #sup {
    display: flex;

    color: rgb(255, 227, 175);
    font-size: 20px;
    margin: auto;
    word-break: break-all;
    text-align: center;
    margin-top: 20vh;

    font-family: "Poppins", sans-serif;
  }

  .contentx,
  .content1 {
    display: none;
  }

  #mobiles {
    display: flex;
  }

  #texts2 {
    display: none;
  }

  #texts1 {
    display: none;
  }

  #texts3 {
    display: none;
  }
}

@media screen and (min-width: 310px) {
  /* animation part*/

  .smalx {
    font-size: 3vw;
  }

  .smalx {
    font-size: 3vw;
  }

  .butt {
    background-color: rgb(17, 189, 251);
    border-radius: 20px;
    width: 8rem;
    height: auto;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    color: white;
    justify-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-self: center;
    margin: 0 auto;
  }

  .butt:hover {
    background-color: rgb(20, 145, 255);
    border-radius: 20px;
    width: 8rem;
    height: auto;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0 auto;
  }

  .butt:active {
    background-color: rgb(4, 88, 255);
    border-radius: 30px;
    width: 8rem;
    margin: 0 auto;
    height: auto;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    flex-direction: row;
    color: white;
    display: flex;
    justify-content: center;
  }

  .img-1 {
    padding: 0;
    margin: 0;
  }
  #navbur {
    display: flex;
    flex-direction: column;
  }
}

#div1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#p3 {
  font-size: 3vh;
  color: rgb(24, 20, 20);
  text-align: center;

  font-family: "Hammersmith One", sans-serif;
}
#p4 {
  font-size: 2vh;
  color: rgb(128, 151, 173);

  text-align: center;

  font-family: "Hammersmith One", sans-serif;
}

.container2 {
  font-size: 1vw;
}

a:link {
  color: rgb(165, 160, 160);
  background-color: transparent;
  text-decoration: none;
}

#imglogo {
  width: 100%;
  max-width: 20vw;
  display: flex;
  align-self: center;
}

a:visited {
  color: rgb(118, 126, 167);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: rgba(0, 85, 255, 0.549);
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: rgb(255, 218, 9);
  background-color: transparent;
  text-decoration: underline;
}

#texts1,
#texts2,
#texts3 {
  font-size: 0.9em;
  display: flex;

  flex-direction: column;
  font-family: "mont", sans-serif;
  animation: moveUx 2s;
  animation-fill-mode: forwards;
  opacity: 0;

  padding-left: 1rem;
  text-align: center;
  color: whitesmoke;
}

.smalx {
  font-family: "Hammersmith One", sans-serif;
}

.buta {
  width: 18vw;
  margin-top: 2vh;
  outline: none;
  height: 5vh;
  color: white;
  background-image: linear-gradient(
    120deg,
    rgb(0, 0, 0),
    #4d5a66,
    #4c5168,
    #4d5a66,
    rgb(0, 0, 0)
  ) !important;
  background-size: 600%;
  animation: bganimation 28s infinite;
  border: none;
  font-family: "mont", sans-serif;
  font-size: 2vh;
  border: 1vh solid white;
  border-radius: 3vw;
  transition-duration: 0.9s;
  margin-bottom: 2vh;
}

.text3 {
  font-size: 3vw;
}

#pic {
  border-radius: 2vw;
}

#p5,
#p7 {
  font-family: "mont", sans-serif;
  color: rgb(255, 255, 255);
  font-size: 2vh;
  font-weight: 800;
  font-synthesis: weight;
  justify-content: center;
}

#p7,
#p6 {
  font-family: "mont", sans-serif;
  color: rgb(128, 151, 173);

  font-weight: 800;
  font-synthesis: weight;
  text-align: center;
  justify-content: center;
}

#texts4,
#texts5,
#texts6 {
  grid-area: text4;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-around;
  display: none;
}

.container2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 5vw;
  margin-top: 2vw;
}

a {
  outline: none;
  text-decoration: none;
  color: initial;
}

.img1 {
  position: relative;

  right: 0px;
  justify-content: space-evenly;
  display: flex;
  width: 3em;
}

#small1 {
  color: white;
  font-size: 1vw;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#p91 {
  font-family: "Hammersmith One", sans-serif;
  text-align: center;
  color: rgb(128, 151, 173);
  word-break: break-word;
  font-weight: 400;
}
#p92 {
  font-family: "Hammersmith One", sans-serif;
  text-align: center;
  white-space: pre;
  color: rgb(253, 253, 253);
  font-weight: 400;
}
#p90,
#pop,
#p4 {
  text-align: center;
  color: rgb(128, 151, 173);
  font-size: 2vh;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#opo {
  text-align: flex-start;
  color: rgb(128, 151, 173);
}
#h390 {
  text-align: center;
  color: rgb(128, 151, 173);
  font-size: 2vh;
  font-family: "Poppins", sans-serif;
}

.divcont,
.divcont2,
.divcont3 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

#container12 {
  justify-content: center;
  display: flex;

  margin-top: 30vh;
  flex-direction: column;
}

.hide {
  display: none;
}

.shows {
  display: block;
}

.tbox1,
.tbox2,
.tbox3,
.tbox4 {
  opacity: 0;
  display: block;
  justify-content: center;

  border-radius: 3vw;
  text-align: center;
  margin-bottom: 4vh;
  align-items: center;
  background-color: white;
  font-family: sans-serif;
  color: rgb(27, 24, 24);
  padding: 2vh;
  animation: moveUx 1s;
  animation-fill-mode: forwards;
}

.custom {
  fill: #53c1de;
}
.none {
  fill: none;
}

.html {
  width: 8rem;

  transition-duration: 2.9s;
}

.javascript {
  border-radius: 21vw;
  align-self: center;
  width: 5rem;
}

.css {
  transition-duration: 2.9s;
  width: 6rem;
}
.react {
  transition-duration: 2.9s;
  align-self: center;
  width: 5rem;
}

@keyframes hoverx {
  from {
    fill: white;
  }
  to {
    fill: aqua;
    fill: white;
  }
  to {
    fill: white;
  }
}
#p1 {
  color: white;
  font-family: "poppins";
  font-size: 4vh;
  margin-top: 5vh;
}

.p2 {
  font-weight: 700;

  animation-fill-mode: forwards;

  color: rgb(17, 14, 14);
  font-family: "poppins", sans-serif;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 6vh;
}

.p31 {
  font-weight: 200;

  animation-fill-mode: forwards;

  color: white;
  font-family: "poppins", sans-serif;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 2vw;
  font-size: 6vh;
}

.p32 {
  font-weight: 200;

  animation-fill-mode: forwards;

  color: rgb(37, 33, 33);
  font-family: "poppins", sans-serif;
  display: flex;
  justify-content: center;
  text-align: center;

  font-size: 3vw;
}

#logox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text3 {
  font-size: 2vw;

  font-family: "Poppins", sans-serif;
  font-weight: 100;
  color: rgb(54, 54, 54);
}

.para3 {
  font-size: 2vw;
  text-align: center;
  color: rgb(31, 30, 30);
  font-family: "Poppins", sans-serif;

  font-weight: 100;
}

.contentd,
.contentc {
  text-align: center;
  width: 100vw;
}

carousel-cell {
  width: 100vw;
}

@media only screen and (max-width: 1000px) {
  #p6 {
    font-size: 1.9em;
  }
  #p26 {
    font-size: 0.4em;
  }
  #p91 {
    font-size: 0.3em;
    display: flex;
    word-break: break-word;
    flex-direction: column;
  }
  #p92 {
    font-size: 0.3em;
    display: flex;
    word-break: break-word;
    flex-direction: column;
  }
  #p99 {
    font-size: 1vh;
    display: flex;
    color: rgb(128, 151, 173);
    flex-direction: column;
    font-weight: 300;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  #container1 {
    background-color: white;

    height: 50vh;
    padding-top: 2vh;

    padding: 2vw;
    animation: moveUx 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    margin-top: 10vh;
    border-radius: 3vw;
    display: flex;
    padding-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;

    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  .content0 {
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
    margin-bottom: 2vh;
    padding: 2vw;
  }
  .content0,
  .content1,
  .content3,
  .content4 {
    padding: 2vw;
    display: flex;
    justify-content: baseline;
    flex-direction: column;
    padding-left: 0;
  }
  #text00 {
    font-size: 2vh;
    align-self: center;
    margin: auto;
    text-align: center;
    word-break: break-word;
    padding-left: 1vw;
    align-items: center;
  }
  #pic {
    display: flex;
    width: 15em;
    height: 7em;
    margin-right: auto;
    margin-left: auto;
  }
}

#p26 {
  font-family: "mont", sans-serif;
  color: rgb(128, 151, 173);

  font-weight: 800;
  font-synthesis: weight;
  text-align: center;
  justify-content: center;
}
@media only screen and (min-width: 1000px) {
  #p99 {
    font-size: 1vh;
    display: flex;
    color: rgb(128, 151, 173);
    flex-direction: column;
    font-weight: 300;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  #p6 {
    font-size: 3em;
  }

  .github:hover {
    width: 10vw;
    transition: 1.4s;
  }
  .linkedin:hover {
    width: 12vw;
    transition: 2.4s;
  }
  .buta:hover {
    border: white;
    background-color: white;
    height: 7vh;
    transition-duration: 1.4s;
    border-radius: 4vw;
    font-size: 2em;
    cursor: pointer;
    font-weight: 700;
    color: rgb(255, 255, 255);
    margin-bottom: 2vh;
  }
  #container1 {
    background-color: white;

    height: auto;
    padding-top: 2vh;

    padding: 2vw;
    animation: moveUx 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    margin-top: 10vh;
    border-radius: 3vw;
    display: flex;
    padding-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: unset;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  #text00 {
    align-self: center;
    word-break: break-word;
  }
  .content0 {
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
    margin-bottom: 1vh;
  }
  .content0,
  .content1,
  .content3,
  .content4 {
    padding: 2vh;
    display: flex !important;

    justify-content: space-around;
    flex-direction: row;
  }
  #pic {
    margin: auto;
    width: 30vw;
    display: flex;
  }
}

#butbois {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#butbois1 {
  display: flex;
  justify-content: space-around;
}

#container1 {
  background-color: white;
  width: 75vw;
  height: auto;
  padding-top: 2vh;

  padding: 2vw;
  animation: moveUx 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-top: 10vh;
  border-radius: 3vw;
  display: flex;
  padding-bottom: 2vw;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  justify-content: center;
}

.container2 {
  display: flex;
  justify-content: space-evenly;
  animation: moveUx 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.linkedin {
  width: 9vw;
  transition: 2s;
}

.github {
  width: 7vw;
  transition: 2s;
}

/* big buttons, no circle */
.flickity-prev-next-button {
  display: none;
}
/* arrow color */
.flickity-prev-next-button .arrow {
  display: none;
}
.flickity-prev-next-button.no-svg {
  display: none;
}
.flickity-prev-next-button:hover {
  display: none;
}
/* hide disabled button */
.flickity-prev-next-button:disabled {
  display: none;
}

/* position dots in gallery */
.flickity-page-dots {
  display: none;
}
/* white circles */
.flickity-page-dots .dot {
  width: 12vw;
  display: none;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: rgb(8, 255, 218);
  width: 2vw;
  display: none;
  transition-duration: 2s;
}

@keyframes moveUd {
  0% {
    display: none;
  }

  50% {
    margin-top: 29%;
    opacity: 0;
  }

  100% {
    margin-top: 0%;
    opacity: 1;
    display: flex;
  }
}

@keyframes moveUp {
  0% {
    display: none;
  }

  50% {
    margin-top: 39%;
    opacity: 0;
  }

  100% {
    margin-top: 0%;
    display: flex;
    opacity: 1;
  }
}

@keyframes moveUd {
  0% {
    display: none;
  }

  50% {
    opacity: 0%;
  }

  50% {
    opacity: 0%;
  }

  100% {
    margin-top: 0%;
    opacity: 100%;
  }
}

@keyframes moveUx {
  0% {
    display: none;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveUp {
  0% {
    display: none;
  }

  50% {
    opacity: 1%;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

#MainContent {
  background-color: rgb(255, 255, 255);
}

#logosi {
  display: flex;
  justify-content: space-evenly;
}

#containesr12 {
  background-color: white;
}

@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.bute {
  font-size: 0.6em;
  margin: auto;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#logo {
  display: flex;
  margin: auto;
  width: 70vw;

  animation: fill 0.6s ease forwards 5.3s;
}
#logo path:nth-child(1) {
  stroke-dasharray: 1007.7213745117188;
  stroke-dashoffset: 1007.7213745117188;
  animation: line-anim 2s ease forwards;
}

#logo path:nth-child(2) {
  stroke-dasharray: 370.1744384765625;
  stroke-dashoffset: 370.1744384765625;
  animation: line-anim 2s ease forwards 0.3s;
}

#logo path:nth-child(3) {
  stroke-dasharray: 322.5216064453125;
  stroke-dashoffset: 322.5216064453125;
  animation: line-anim 2s ease forwards 0.6s;
}

#logo path:nth-child(4) {
  stroke-dasharray: 393.2339782714844;
  stroke-dashoffset: 393.2339782714844;
  animation: line-anim 2s ease forwards 0.9s;
}

#logo path:nth-child(5) {
  stroke-dasharray: 491.7581481933594;
  stroke-dashoffset: 491.7581481933594;
  animation: line-anim 2s ease forwards 0.12s;
}

#logo path:nth-child(6) {
  stroke-dasharray: 737.6006469726562;
  stroke-dashoffset: 737.6006469726562;
  animation: line-anim 2s ease forwards 0.15s;
}

#logo path:nth-child(7) {
  stroke-dasharray: 460.0328674316406;
  stroke-dashoffset: 460.0328674316406;
  animation: line-anim 2s ease forwards 1s;
}

#logo path:nth-child(8) {
  stroke-dasharray: 573.7779541015625;
  stroke-dashoffset: 573.7779541015625;
  animation: line-anim 2s ease forwards 2s;
}

#logo path:nth-child(9) {
  stroke-dasharray: 350.5067138671875;
  stroke-dashoffset: 350.5067138671875;
  animation: line-anim 2s ease forwards 3s;
}

#logo path:nth-child(10) {
  stroke-dasharray: 499.12957763671875;
  stroke-dashoffset: 499.12957763671875;
  animation: line-anim 2s ease forwards 4s;
}

p {
  text-align: center;
  color: white;
}
@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}
